.badge-container > div > div:first-of-type {
  height: 12px;
  width: 12px;
}

.badge-container > div {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 4px;
  padding-right: 4px;
}
