.withdraw-container input[type='number'] {
  -webkit-appearance: none;
  margin: 0;
}

.withdraw-container input[type='number']::-webkit-outer-spin-button,
.withdraw-container input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.withdraw-container input[type='number'] {
  -moz-appearance: textfield;
}
